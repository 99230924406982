import { DIMENSIONS } from 'modules/device';
import { StyleSheet } from 'react-native';
import { Colors } from 'ui/foundation';

export default StyleSheet.create({
  input: {
    padding: 0,
    margin: 0,
    marginLeft: 6,
    flex: 1,
    color: Colors.black,
  },
  container: {
    flex: 1,
    height: 40,
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 30,
    backgroundColor: 'rgba(239, 239, 239, 1)',
    overflow: 'hidden',
    paddingHorizontal: 15,
  },
  text: {
    flex: 1,
  },
  icon: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
