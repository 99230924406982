
export const spacings = {
  s1: 4,   //  xsmall
  s2: 8,   //  small
  s3: 12,  //  sMedium
  s4: 16,  //  medium
  s5: 20,  //  mLarge
  s6: 24,  //  large
  s7: 32,  //  xlarge
};
