import { systemWeights } from 'react-native-typography';
import { Platform } from 'react-native';
import { deviceHeight, deviceWidth } from 'ui/spacing';

export const COLORS = {
  brand: '#71B8CB',
  brandLight: '#8EDAEA',
  darkBrand: '#298192',
  greenLight: '#71CBC6',
  linkWater: '#c1cede',
  linkWater30: '#c1cede4D',
  border: '#ebeef2',
  notInTheMood: '#7C8CAC',
  splash: Platform.select({ default: '#71B8CB', web: '#EDF1F7' }),
  white: '#ffffff',
  grey0: '#fbfbfe',
  grey1: '#b2b2b7',
  grey2: '#838388',
  grey3: '#3a3a42',
  grey4: '#161618',
  grey6: '#cccccc',
  grey7: '#212121',
  grey11: 'rgba(34, 34, 40, 0.67)',
  greyOpacity47: 'rgba(34, 34, 40, 0.47)',
  greyOpacity06: 'rgba(77, 76, 86, 0.06)',
  black: '#000000',
  dividerGrey: 'rgba(151, 151, 151, 0.3)',
  dropShadow: 'rgba(0,0,0,.35)',
  profileBorder: 'rgba(0,0,0,.10)',
  paleGrey: '#fbfbfe',
  lightGrey: '#d8d8db',
  borderGrey: '#f1f2f2',
  transparent: 'rgba(0, 0, 0, 0)',
  overlay: 'rgba(0, 0, 0, 0.6)',
  indicator: '#fff',
  transparentWhite: '#FFFFFF80',
  defaultGraph: '#EEF9F5',
  blackText: '#2C2F49',
  separator: '#F6F8FA',
  background: '#EDF1F7',
  currentUserReply: '#EDFFFD',
  disabled: '#EBEEF2',
  inNeedOfHelp: '#E9655B',
  makingIt: '#7FA648',
  goToThread: '#8DDAEA',
  feelingStrong: '#598EFF',
  anxious: '#F3A761',
  lessStressed: '#F4CA34',
  undecided: '#BCA9EA',
  greyCancel: '#8E8E93',
  secondTopic: '#BBE087',
  fourthTopic: '#FFE36C',
  orange: '#FF8666',
  checkIn: '#f2fafb',
  checkInDetailsText: '#7E8CAB',
  avatarBorder: '#98d9dd',

  borderV2: '#D9D9D9',
  black87: 'rgba(0, 0, 0, 0.87)',
  blue: '#41ABC8',
  lightBlue: "#F4FAFC",

};
export const X_WIDTH = 375;
export const X_HEIGHT = 812;
export const XSMAX_WIDTH = 414;
export const XSMAX_HEIGHT = 896;

export const IS_IPHONE_X = (() => {
  if (Platform.OS === 'web' || Platform.OS === 'android') {
    return false;
  }

  return (
    (Platform.OS === 'ios' &&
      ((deviceHeight >= X_HEIGHT && deviceWidth >= X_WIDTH) ||
        (deviceHeight >= X_WIDTH && deviceWidth >= X_HEIGHT))) ||
    (deviceHeight >= XSMAX_HEIGHT && deviceWidth >= XSMAX_WIDTH) ||
    (deviceHeight >= XSMAX_WIDTH && deviceWidth >= XSMAX_HEIGHT)
  );
})();

export const SHADOWS = {
  default: {
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 4,
    shadowOpacity: 1,
    elevation: 1,
  },
  intensive: {
    shadowColor: 'rgba(0, 0, 0, 0.23)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 3,
    shadowOpacity: 1,
    elevation: 5,
  },
  input: {
    shadowColor: 'rgba(0, 0, 0, 0.08)',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 6,
    shadowOpacity: 1,
    elevation: 3,
  },
  header: {
    shadowColor: 'rgba(99, 106, 117, 0.16)',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowRadius: 6,
    shadowOpacity: 1,
    elevation: 3,
  },
  none: {
    shadowRadius: 0,
    shadowOpacity: 0,
    shadowOffset: {
      width: 0,
      height: 0,
    },
  },
  reactions: {
    shadowColor: 'rgba(44, 47, 73, 0.15)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 4,
    shadowOpacity: 1,
    elevation: 1,
  },
};

export const FONT_WEIGHTS = {
  thin: systemWeights.thin,
  light: systemWeights.light,
  regular: systemWeights.regular,
  semibold: systemWeights.semibold,
  bold: systemWeights.bold,
};

export const FONT_SIZES = {
  content: {
    fontSize: 15,
    lineHeight: 18,
  },
  article: {
    fontSize: 19,
    lineHeight: 26,
  },
  meta: {
    fontSize: 12,
  },
};

export const TOUCHABLE_SIZE = 44;

export const TOUCHABLE_ACTIVE_OPACITY = 0.8;

export const HORIZONTAL_INDENT = 24; // default horizontal offset from the screen edge
