export const NAV_TYPES_ONBOARDING_STACK = {
  INTERESTS: 'ONBOARD_INTERESTS',
  MAIN_INTEREST: 'MAIN_ONBOARD_INTERESTS',
  ONBOARDING_COMPLETE: 'ONBOARDING_COMPLETE',

  PHOTOS: 'PHOTOS',
  ONBOARDING_SUBSCRIPTION: 'ONBOARDING_SUBSCRIPTION',
  ONBOARDING_CATEGORIES: 'ONBOARDING_CATEGORIES',
  ONBOARDING_SUGGESTED_GROUPS: 'ONBOARDING_SUGGESTED_GROUPS',
  ONBOARDING_GROUPS_BY_CATEGORY: 'ONBOARDING_GROUPS_BY_CATEGORY',
  ONBOARDING_SEARCH_GROUPS: 'ONBOARDING_SEARCH_GROUPS',
  PHONE: 'PHONE',
  GENDER: 'GENDER',
  HAVE_YOU_BEEN_DIAGNOSED: 'HAVE_YOU_BEEN_DIAGNOSED',
  CLAIM_YOUR_DIAGNOSIS: 'CLAIM_YOUR_DIAGNOSIS',
  HOW_DO_YOU_FEEL: 'HOW_DO_YOU_FEEL',
  BIRTHDAY: 'BIRTHDAY',
  WELCOME: 'WELCOME',
};

export const NAV_TYPES_CHAT_STACK = {
  CONVERSATION: 'conversation',
  CONVERSATION_LIST: 'conversations',
  PUBLIC_CONVERSATION_LIST: 'PUBLIC_CONVERSATION_LIST',
  CONVERSATION_JOIN: 'CONVERSATION_JOIN',
  CONVERSATION_INVITE: 'CONVERSATION_INVITE',
  CONVERSATION_CREATE: 'CONVERSATION_CREATE',
  CONVERSATION_EDIT: 'CONVERSATION_EDIT',
  CONVERSATION_EDIT_INVITE: 'CONVERSATION_EDIT_INVITE',
  CHAT_GET_IN_TOUCH: 'CHAT_GET_IN_TOUCH',
  DISCOVER_GROUPS: 'DISCOVER_GROUPS',
  SUGGESTED_GROUPS: 'SUGGESTED_GROUPS',
  GROUPS_BY_CATEGORY: 'GROUPS_BY_CATEGORY',
  CATEGORIES: 'CATEGORIES',
  SEARCH_GROUPS: 'SEARCH_GROUPS',
};

export const NAV_TYPES_GROUPS_STACK = {
  GROUPS: 'groups',
  GROUPS_DISCOVER: 'groups_discover',
  SEARCH_GROUPS: 'search_groups',
  SEARCH_GROUPS_INTERESTS: 'search_groups_interests',
  CONVERSATION_GROUP: 'conversation_group',
};

export const POST_CREATE_STACK = {
  INDEX: 'INDEX',
  POST_CREATE_INTERESTS: 'POST_CREATE_INTERESTS',
  CREATE_POST_INTERESTS: 'CREATE_POST_INTERESTS',
};

export const HEALTH_TRACKER_STACK = {
  HEALTH_CHECK_IN: 'HEALTH_CHECK_IN',
};

export const SYMPTOMS_CHECKER_STACK = {
  SYMPTOMS_CHECKER: 'SYMPTOMS_CHECKER',
  SYMPTOMS_RESULT: 'SYMPTOMS_RESULT',
};

export const BOOKMARKS_STACK = {
  INDEX: 'index',
};
