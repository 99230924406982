import { TextStyle } from 'react-native';
import { TypographyPredefinedTypes } from './types';

// Define available font sizes and weights
const fontSizes: Record<string, number> = {
  '10': 10,
  '11': 11,
  '12': 12,
  '14': 14,
  '16': 16,
  '18': 18,
  '22': 22,
  '24': 24,
  '27': 27,
};

const fontWeights: Record<string, TextStyle['fontWeight']> = {
  'R': '400', // Regular
  'M': '500', // Medium
  'L': '300', // Light
  'B': 'bold', // Bold
  'SB': '600', // SemiBold
};

// Dynamically generate the typographyStyles object
export const typographyStyles: Record<string, TextStyle> = Object.keys(fontSizes).reduce((acc, size) => {
  Object.keys(fontWeights).forEach(weight => {
    const key = `text${size}${weight}`;
    acc[key] = {
      fontSize: fontSizes[size],
      fontWeight: fontWeights[weight],
      fontFamily: 'Inter-' + (weight === 'R' ? 'Regular' : weight === 'M' ? 'Medium' : weight === 'L' ? 'Light' : weight === 'B' ? 'Bold' : 'SemiBold'),
    };
  });
  return acc;
}, {});

// Get a typography style based on a prop name
export const getTypographyStyle = (propName: string): TextStyle | undefined => {
  return typographyStyles[propName];
};

export type FontSize = '10' | '11' | '12' | '14' | '16' | '18' | '22' | '24' | '27';
export type FontWeight = 'R' | 'M' | 'L' | 'B' | 'SB';

export type DynamicTypographyNames = {
  [Key in `${FontSize}${FontWeight}`]?: boolean;
};

export type TypographyProps =  TypographyPredefinedTypes & DynamicTypographyNames;