import { TextStyle } from "react-native";

export const Colors = {
  // Primary 
  primaryLightest: 'rgba(65, 171, 200, 0.1)',
  primaryLighter: 'rgba(65, 171, 200, 0.3)',
  primaryLight: 'rgba(65, 171, 200, 0.6)',
  primary: 'rgba(65, 171, 200, 1)',

  // Blue
  blueLightest: 'rgba(0, 136, 212, 0.1)',
  blueLighter: 'rgba(0, 136, 212, 0.3)',
  blueLight: 'rgba(0, 136, 212, 0.6)',
  blue: 'rgba(0, 136, 212, 1)',

  // Cyan 
  cyanLightest: 'rgba(0, 186, 164, 0.1)',
  cyanLighter: 'rgba(0, 186, 164, 0.3)',
  cyanLight: 'rgba(0, 186, 164, 0.6)',
  cyan: 'rgba(0, 186, 164, 1)',

  // Green 
  greenLightest: 'rgba(107, 175, 12, 0.1)',
  greenLighter: 'rgba(107, 175, 12, 0.3)',
  greenLight: 'rgba(107, 175, 12, 0.6)',
  green: 'rgba(107, 175, 12, 1)',

  // Yellow 
  yellowLightest: 'rgba(229, 179, 0, 0.1)',
  yellowLighter: 'rgba(229, 179, 0, 0.3)',
  yellowLight: 'rgba(229, 179, 0, 0.6)',
  yellow: 'rgba(229, 179, 0, 1)',

  // Orange 
  orangeLightest: 'rgba(229, 119, 11, 0.1)',
  orangeLighter: 'rgba(229, 119, 11, 0.3)',
  orangeLight: 'rgba(229, 119, 11, 0.6)',
  orange: 'rgba(229, 119, 11, 1)',

  // Red 
  redLightest: 'rgba(217, 10, 10, 0.1)',
  redLighter: 'rgba(217, 10, 10, 0.3)',
  redLight: 'rgba(217, 10, 10, 0.6)',
  red: 'rgba(217, 10, 10, 1)',

  // Gray
  
  lightGray: 'rgba(217, 217, 217, 1)',
  gray:'rgba(0, 0, 0, 0.12)',
  darkGray:'rgba(0, 0, 0, 0.3)' ,
  darkerGray:'rgba(102, 102, 102, 1)',

  borderGray:"rgba(217, 217, 217, 1)",
  containerMainColor:'rgba(65, 171, 200, 0.08)',

  black:'rgba(33, 33, 33, 0.87)',
white:'white',
};


export const getColorStyle = (propValue: string): TextStyle | undefined => {
  if (Colors[propValue]) {
    return { color: Colors[propValue] };
  }
  return undefined;
};

// Creating a type for color props based on the colors object
export type ColorProps = {
  [K in keyof typeof Colors]?: boolean;
};
